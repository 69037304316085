import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Fog
// const fog = new THREE.Fog('#262837', 1, 30)
// scene.fog = fog

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const groundTex = textureLoader.load('./sandDune.jpg')
const groundHeight = textureLoader.load('./groundHeight.png')
const groundAlpha = textureLoader.load('./groundAlpha.png')

/**
 * Materials
 */
const groundMat = new THREE.MeshStandardMaterial({
    color: 'gray',
    map: groundTex,
    displacementMap: groundHeight,
    displacementScale: 0.45,
    alphaMap: groundAlpha,
    transparent: true,
    depthTest: false
})

/**
 * Geometries
 */
const groundGeo = new THREE.PlaneGeometry(5, 5, 64, 64)

/**
 * Meshes
 */
const groundMesh = new THREE.Mesh(groundGeo, groundMat)
groundMesh.rotation.x = 181
scene.add(groundMesh)

/**
 * Lights
 */
const light = new THREE.PointLight('#ce8540', 2.2)
light.position.set(2,3,4)
scene.add(light)

// const col = {color: 'blue'}
// gui.addColor(col, 'color').onChange(() => {
//     light.color.set(col.color)
// })

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 3
scene.add(camera)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.setClearColor('#262837')


/**
 * Animate
 */
document.addEventListener('mousemove', animateTerrain)

let mouseY = 0

function animateTerrain(event) {
    mouseY = event.clientY
}

const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    groundMesh.rotation.z = .1 * elapsedTime
    groundMesh.material.displacementScale = .3 + mouseY * .00009

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()